export const appSettings = {
  name: 'Saboia 1257',
  description: "",
  layout: "vertical-two",
  type: 'food', // 'food', 'delivery' or 'office',
  system: 'deposit', // 'pickup' or 'deposit'
  logo: {
    url: "/logo_saboia.svg",
    alt: "saboia-log",
    href: "/",
    width: 300,
    height: 100,
  },
  logoMobile: {
    url: "",
    alt: "",
    href: "/",
    width: 512,
    height: 512,
  },
  defaultLanguage: "pt",
  author: {
    name: "Purple Profile",
    websiteUrl: "https://purpleprofile.pt",
    address: "",
  },
  numpad: {
    codeReset: 6 //Time in seconds of code reset on numpad
  },
  slider: {
    animation: 'slide',  //'slide', 'vertical-slide' or 'rotate'
    timing: 'ease',  //'ease', 'ease-in', 'ease-out', 'ease-in-out', 'linear'
    duration: 150,  //'ease', 'ease-in', 'ease-out', 'ease-in-out', 'linear'
  },
  countdown: {
    size: '40',
    strokeWidth: '2',
  },
  toast: {
    className: 'bg-white',
    success: {
      className: 'bg-white',
      duration: 5000,
    },
    error: {
      className: 'bg-white',
      duration: 5000
    },
  }
}